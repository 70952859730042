import * as types from "../types";

import buApi from "@/api/business_unit";
import catApi from "@/api/emission_categories";
import periodApi from "@/api/period";
import siteApi from "@/api/site";
import tagApi from "@/api/tags";

const getSites = async () => {
    try {
        const result = await siteApi.list();
        return result.data;
    } catch (error) {
        console.log(error);
    }
};

const getDeletedSites = async () => {
    try {
        const result = await siteApi.listDeleted();
        return result.data;
    } catch (error) {
        console.log(error);
    }
};

const getPeriods = async () => {
    try {
        const result = await periodApi.list();
        return result.data;
    } catch (error) {
        console.log(error);
    }
};

const getBusinessUnits = async () => {
    try {
        const result = await buApi.list();
        return result.data;
    } catch (error) {
        console.log(error);
    }
};

const getCategories = async () => {
    try {
        const result = await catApi.getSelected();
        return result.data;
    } catch (error) {
        console.log(error);
    }
};

const getTags = async () => {
    try {
        const result = await tagApi.listAll();
        return result.data;
    } catch (error) {
        console.log(error);
    }
};

const pcafClasses = [
    {
        name: "Listed equity and corporate bonds",
        value: "listed_equity_corporate_bonds",
        available: false,
    },
    {
        name: "Business loans and unlisted equity",
        value: "business_loans_unlisted_equity",
        available: false,
    },
    {
        name: "Commercial real estate",
        value: "commercial_real_estate",
        available: true,
    },
    {
        name: "Project finance",
        value: "project_finance",
        available: false,
    },
    {
        name: "Mortgages",
        value: "mortgages",
        available: true,
    },
    {
        name: "Motor vehicle loans",
        value: "motor_vehicle_loans",
        available: false,
    },
];

export const globalData = {
    state: {
        pcafClasses,
        sites: [],
        periods: [],
        businessUnits: [],
        categories: [],
        tags: [],
        preloaded: false,
    },
    mutations: {
        [types.MUTATE_SET_PERIODS]: (state, periods) => {
            state.periods = [...periods];
        },
        [types.MUTATE_SET_DATA]: (state, data) => {
            state[data.name] = data.data;
        },
        [types.MUTATE_SET_PRELOADED]: (state) => {
            state.preloaded = true;
        },
    },
    actions: {
        [types.SET_PERIODS]: ({ commit }, periods) => {
            commit(types.MUTATE_SET_PERIODS, periods);
        },
        [types.SET_DATA]: ({ commit }, data) => {
            commit(types.MUTATE_SET_DATA, data);
        },
        [types.POPULATE]: async ({ commit, state }, force = false) => {
            if (state.preloaded && !force) return;

            const sites = await getSites();
            const periods = await getPeriods();
            const businessUnits = await getBusinessUnits();
            const categories = await getCategories();
            const tags = await getTags();
            const deletedSites = await getDeletedSites();

            const values = await Promise.all([
                sites,
                periods,
                businessUnits,
                categories,
                tags,
                deletedSites,
            ]);

            // SET THE PRELOADED VALUES
            await commit(types.MUTATE_SET_DATA, {
                name: "sites",
                data: values[0],
            });
            await commit(types.MUTATE_SET_DATA, {
                name: "periods",
                data: values[1],
            });
            await commit(types.MUTATE_SET_DATA, {
                name: "businessUnits",
                data: values[2],
            });
            await commit(types.MUTATE_SET_DATA, {
                name: "categories",
                data: values[3],
            });
            await commit(types.MUTATE_SET_DATA, {
                name: "tags",
                data: values[4],
            });
            await commit(types.MUTATE_SET_DATA, {
                name: "deletedSites",
                data: values[5],
            });

            // Confirm the preload so we don't have to do it again
            await commit(types.MUTATE_SET_DATA, {
                name: "preloaded",
                data: true,
            });
        },
    },
    getters: {
        getPeriod: (state) => (id) => {
            return state.periods.find((x) => x.id === id);
        },
        getClosestPeriod: (state) => (year) => {
            const period = state.periods.find((x) => {
                const yearStart = new Date(year, 1, 1).getTime();
                const start = new Date(x.start_date).getTime();
                const end = new Date(x.end_date).getTime();
                return start <= yearStart && yearStart < end;
            });

            return period;
        },
        getSite: (state) => (id) => {
            return state.sites.find((x) => x.id === id);
        },
        getSites: (state) => () => {
            return state.sites;
        },
        getDeletedSite: (state) => (id) => {
            return state.deletedSites.find((x) => x.id === id);
        },
        getDeletedSites: (state) => () => {
            return state.deletedSites;
        },
        getBusinessUnits: (state) => () => {
            return state.businessUnits;
        },
        getBusinessUnit: (state) => (id) => {
            return state.businessUnits.find((x) => x.id === id);
        },
        findDataPoint: (state) => (dataName, id) => {
            return state[dataName].find((x) => x.id === id);
        },
        getTag: (state) => (id) => {
            return state.tags.find((x) => x.id === id);
        },
        getTags: (state) => () => {
            return state.tags;
        },
        getPcafClasses: (state) => () => {
            return state.pcafClasses;
        },
    },
};
