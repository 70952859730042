<template>
    <div>
        <Select
            v-model="selectedPeriod"
            :options="periods"
            optionLabel="name"
            filter
            checkmark
            :pt="{
                dropdown: {
                    class: 'hidden',
                },
            }"
        >
            <template #value="{ value }">
                <div class="flex align-items-center gap-2 menu-button">
                    <i class="fa-solid fa-calendar" />
                    <div>{{ value?.name }}</div>
                </div>
            </template>
            <template #option="slotProps">
                <div class="flex align-items-center gap-3">
                    <i
                        class="fa-solid fa-calendar"
                        v-if="slotProps.option.locked === 0"
                    ></i>
                    <i class="fas fa-lock" v-else></i>

                    <div>{{ slotProps.option.name }}</div>
                </div>
            </template>
        </Select>
    </div>
</template>

<script>
import Select from "primevue/select";
import * as types from "@/store/types";
import { mapState } from "vuex";

export default {
    name: "CCPeriods",
    components: {
        Select,
    },
    props: {
        highlight: {
            type: Boolean,
            required: false,
            default: false,
        },
        small: {
            type: Boolean,
            required: false,
            default: false,
        },
        active: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    computed: {
        ...mapState({
            periods: (state) => state.data.periods,
            currentPeriod: (state) => state.filters.period,
        }),
        period() {
            return this.$store.getters.getCurrentPeriod();
        },
        selectedPeriod: {
            get() {
                return this.periods.find((p) => p.id === this.currentPeriod);
            },
            set(value) {
                this.selectPeriod(value.id);
            },
        },
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            if (!this.periods) return;
            if (this.periods.length === 0) return;

            try {
                // This value gets reset when switching companies. We get the currently set period and see if the ID
                // is included in the list of periods. If it is, we set it as the current period. If not, we set the
                // last period in the list as the current period.
                const currentValue = +localStorage.getItem("period");
                const indexOfPeriod = this.periods.findIndex(
                    (x) => x.id === currentValue
                );

                if (currentValue && indexOfPeriod !== -1) {
                    // Don't actively re-set the current period if it's already set (prevents needless re-renders down
                    // the line)
                    if (currentValue === this.currentPeriod) return;
                    this.selectPeriod(currentValue);
                } else {
                    this.selectPeriod(this.periods[this.periods.length - 1].id);
                }
            } catch (error) {
                console.log(error);
            }
        },
        selectPeriod(id) {
            this.$store.dispatch(types.SET_FILTER, { period: id });
        },
    },
    watch: {
        periods: {
            handler() {
                this.init();
            },
        },
    },
};
</script>
