<template>
    <div v-if="tags.length > 0">
        <transition-group name="badge-animation" tag="div">
            <Chip
                class="badge badge--blue mr-2 badge--close badge-animation-item"
                v-for="tag in tags"
                :key="tag.id"
                @click="deleteFilter(tag.id)"
            >
                {{ tag.title }}
            </Chip>
        </transition-group>
    </div>
</template>

<script>
import * as types from "@/store/types";
import Chip from "primevue/chip";

export default {
    components: {
        Chip,
    },
    props: {
        filters: {
            type: Object,
            required: true,
        },
    },
    computed: {
        tags() {
            const tags = [];

            this.filters.tags.forEach((tag) => {
                const currentTag = this.$store.getters.findDataPoint(
                    "tags",
                    tag
                );
                if (!currentTag) return;
                tags.push(currentTag);
            });

            return tags;
        },
    },
    methods: {
        deleteFilter(id) {
            // Find the filter index
            const tagIndex = this.filters.tags.findIndex((x) => x === id);

            // And remove the tag
            const selectedTags = [
                ...this.filters.tags.slice(0, tagIndex),
                ...this.filters.tags.slice(tagIndex + 1),
            ];
            this.$store.dispatch(types.SET_FILTER, {
                tags: selectedTags,
            });
        },
    },
};
</script>
