<template>
    <div v-if="metricValue">
        <span class="custom-metric-sidebar__content-header mb-4">
            <Periods
                small
                ref="periods"
                highlight
                active
                id="period_badge"
                v-tooltip.bottom="$t('select_period')"
            />
            <Chip class="mx-4 text-sm" style="color: black">
                {{ customMetric?.name }}
            </Chip>
        </span>
        <metric-value-data-input
            :metricValue="metricValue"
            :unitDetails="customMetric.unit"
            @refresh="$emit('refresh')"
            @close="$emit('close')"
        >
            <template v-slot:attachments>
                <slot name="attachments"></slot>
            </template>
        </metric-value-data-input>
    </div>
</template>

<script>
import Periods from "@/components/controls/periods/Periods";
import Chip from "primevue/chip";
import MetricValueDataInput from "./MetricValueDataInput.vue";
import { mapState } from "vuex";

export default {
    components: {
        Periods,
        Chip,
        MetricValueDataInput,
    },
    props: {
        metricValue: {
            type: Object,
            required: false,
            default: null,
        },
        customMetric: {
            type: Object,
            required: true,
            default: null,
        },
    },
    computed: {
        ...mapState({
            period: (state) => state.filters.period,
        }),
    },
    watch: {
        metricValue: {
            handler(value) {
                this.$emit("input-update", value);
            },
            deep: true,
        },
        period: {
            handler() {
                this.$emit("refresh");
            },
        },
    },
};
</script>
