<template>
    <Timeline
        :value="activityData"
        :pt="{
            eventOpposite: 'flex-grow-0 white-space-nowrap mt-1',
        }"
    >
        <template #marker="slotProps">
            <Avatar
                :label="getAvatarText(slotProps.item)"
                shape="circle"
                class="p-1 mx-2"
            />
        </template>
        <template #content="slotProps">
            <p class="m-0 text-sm light">
                {{ createTimestamp(slotProps.item) }}
            </p>
            <span
                class="text-sm"
                v-html="
                    expanded
                        ? getDescriptionExpanded(slotProps.item)
                        : getDescription(slotProps.item)
                "
            ></span>
        </template>
    </Timeline>
</template>

<script setup lang="ts">
import { computed } from "vue";
import Timeline from "primevue/timeline";
import Avatar from "primevue/avatar";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import * as types from "@/store/types";
import { createFormattedTime } from "@/util/time";
import { useI18n } from "vue-i18n";
const { t } = useI18n();

defineProps({
    activityData: {
        type: Array,
        required: true,
    },
    expanded: {
        type: Boolean,
        required: false,
        default: false,
    },
});

const store = useStore();
const router = useRouter();

const bus = computed(() => store.state.data.businessUnits);
const sites = computed(() => store.state.data.sites);

const getAvatarText = (d: any): string => {
    if (!d.first_name && !d.last_name) return "?";
    return `${d.first_name[0]}${d.last_name[0]}`;
};

const getUserName = (d: any): string => {
    if (!d.first_name && !d.last_name) return t("al_unknown");
    return `${d.first_name} ${d.last_name}`;
};

const createTimestamp = (d: any): string => {
    return createFormattedTime(d.timestamp);
};

const getEventInfo = (d: any): any => {
    // The event_info_json might be an object, or might be a stringified object OR just a string, find out which and if it's an object, return it as an object
    if (typeof d.event_info_json === "object") {
        return d.event_info_json;
    } else if (
        typeof d.event_info_json === "string" &&
        d.event_info_json[0] === "{"
    ) {
        return JSON.parse(d.event_info_json);
    } else {
        return d.event_info_json;
    }
};

// Shortened, used in activity tab and in the site history
const getDescription = (d: any): string => {
    const eventInfo = getEventInfo(d);
    const user = getUserName(d);
    const name = eventInfo.name;
    const value = eventInfo.value || "";
    const unit = eventInfo?.emission_type?.unit || "";
    const type = eventInfo?.emission_type?.type || "";
    const filename = eventInfo?.filename;
    const period = d.period_name;
    const new_site_name = eventInfo?.name || "";
    const new_group_name = eventInfo?.group_name || "";
    const in_use_period_name = eventInfo?.in_use_period_name || "";
    const out_of_use_period_name = eventInfo?.out_of_use_period_name || "";

    // Add the exception for when the group is removed
    if (d.event_type === "site_updated_group_id") {
        if (!new_group_name) {
            return `${t(`al_site_updated_group_id_no_group`, {
                user,
                name,
                new_site_name,
            })}`;
        }
    }
    // Add the exception for when in-use info is removed
    if (d.event_type === "site_updated_in_use") {
        if (!in_use_period_name) {
            return `${t(`al_site_updated_in_use_no_period`, {
                user,
                name,
                new_site_name,
            })}`;
        }
    }
    // Add the exception for when city is unarchived
    if (d.event_type === "site_updated_out_of_use") {
        if (!out_of_use_period_name) {
            return `${t(`al_site_updated_out_of_use_no_period`, {
                user,
                name,
                new_site_name,
            })}`;
        }
    }

    return t(`al_${d.event_type}`, {
        user,
        name,
        value,
        unit,
        type,
        filename,
        period,
        new_site_name,
        new_group_name,
        in_use_period_name,
        out_of_use_period_name,
    });
};

// Expanded, used on the dashboard
const getDescriptionExpanded = (d: any): string => {
    const eventInfo = getEventInfo(d);
    const user = getUserName(d);
    const name = `<span class="bold">${d.emission_source_name}</span>`;
    const value = eventInfo.value || "";
    const unit = eventInfo?.emission_type?.unit || "";
    const type = eventInfo?.emission_type?.type || "";
    const bu = bus.value.find((b) => b.id === d.business_unit_id)?.name;
    const site = sites.value.find((s) => s.id === d.site_id)?.name;
    const period = d.period_name;
    const tag = `<span class="bold">${d.event_info_json}</span>`;
    const cm_name = `<span class="bold">${eventInfo?.name}</span>`;
    const cm_value = eventInfo.value;
    const cm_unit = eventInfo?.unit || "";
    const cm_domain = eventInfo?.description || "";
    const new_site_name = eventInfo?.name || "";
    const new_group_name = eventInfo?.group_name || "";
    const in_use_period_name = eventInfo?.in_use_period_name || "";
    const out_of_use_period_name = eventInfo?.out_of_use_period_name || "";

    // Add the exception for when the group is removed
    if (d.event_type === "site_updated_group_id") {
        if (!new_group_name) {
            return `${t(`al_site_updated_group_id_no_group`, {
                user,
                name,
                new_site_name,
            })}`;
        }
    }
    // Add the exception for when in-use info is removed
    if (d.event_type === "site_updated_in_use") {
        if (!in_use_period_name) {
            return `${t(`al_site_updated_in_use_no_period`, {
                user,
                name,
                new_site_name,
            })}`;
        }
    }
    // Add the exception for when city is unarchived
    if (d.event_type === "site_updated_out_of_use") {
        if (!out_of_use_period_name) {
            return `${t(`al_site_updated_out_of_use_no_period`, {
                user,
                name,
                new_site_name,
            })}`;
        }
    }

    return `${t(`al_${d.event_type}`, {
        user,
        name,
        value,
        unit,
        type,
        period,
        tag,
        cm_name,
        cm_value,
        cm_unit,
        cm_domain,
        bu,
        site,
        new_site_name,
        new_group_name,
        in_use_period_name,
        out_of_use_period_name,
    })} 
    ${site ? `${t("in")} ${site}` : ""}
    `;
};

const handleRecentActivityClicked = (d: any): void => {
    if (d.subject !== "emission_data" && d.subject !== "emission_source")
        return;

    if (d.subject === "emission_data") {
        store.dispatch(types.SET_FILTER, { period: d.period_id });
    }

    router.push({
        name: "DataInput",
        query: {
            source: d.emission_source_id,
        },
    });
};
</script>
