import * as types from "./types";
import Vuex from "vuex";

import { dataInput } from "./modules/dataInput";
import { globalData } from "./modules/globalData";

const retrieveState = (key) => {
    const value = localStorage.getItem(key);
    if (!value || value === "null" || value === "undefined") {
        switch (key) {
            case "sites":
                return [];
            case "businessUnits":
                return [];
            case "scopes":
                return [];
            case "tags":
                return [];
            default:
                return null;
        }
    }

    return JSON.parse(value);
};

const initialState = {
    company: null,
    companies: [],
    user: null,
    error: null,
    filters: {
        period: retrieveState("period"),
        businessUnits: retrieveState("businessUnits"),
        scopes: retrieveState("scopes"),
        category: retrieveState("category"),
        sites: retrieveState("sites"),
        tags: retrieveState("tags"),
        validation: retrieveState("validation"),
        search: null,
    },
    toast: "",
    showLoader: true,
};

export default new Vuex.Store({
    modules: {
        dataInput,
        data: globalData,
    },
    state: {
        ...initialState,
    },
    mutations: {
        [types.MUTATE_SET_COMPANY]: (state, company) => {
            state.company = company;
        },
        [types.MUTATE_SET_COMPANIES]: (state, companies) => {
            state.companies = companies;
        },
        [types.MUTATE_SET_USER]: (state, user) => {
            state.user = user;
        },
        [types.MUTATE_THROW_ERROR]: (state, error) => {
            state.error = error;
        },
        [types.MUTATE_FILTERS]: (state, filter) => {
            state.filters = { ...state.filters, ...filter };
        },
        [types.MUTATE_SET_TOAST]: (state, toast) => {
            state.toast = toast;
        },
        [types.MUTATE_SHOW_LOADER]: (state, show) => {
            state.showLoader = show;
        },
        [types.MUTATE_RESET_FILTERS]: (state) => {
            state.filters = {
                ...state.filters,
                businessUnits: [],
                scopes: [],
                category: null,
                sites: [],
                tags: [],
                validation: null,
                search: null,
            };
        },
    },
    actions: {
        [types.SET_COMPANY]: ({ commit }, company) => {
            if (company) sessionStorage.setItem("selectedCompany", company.id);
            commit(types.MUTATE_SET_COMPANY, company);
        },
        [types.SET_COMPANIES]: ({ commit }, companies) => {
            commit(types.MUTATE_SET_COMPANIES, companies);
        },
        [types.SET_USER]: ({ commit }, user) => {
            commit(types.MUTATE_SET_USER, user);
        },
        [types.THROW_ERROR]: ({ commit }, error) => {
            commit(types.MUTATE_THROW_ERROR, error);
        },
        [types.SET_FILTER]: ({ commit }, filter) => {
            const filters = Object.keys(filter);
            filters.forEach((f) => {
                localStorage.setItem(f, JSON.stringify(filter[f]));
            });
            commit(types.MUTATE_FILTERS, filter);
        },
        [types.SET_TOAST]: ({ commit }, toast) => {
            commit(types.MUTATE_SET_TOAST, toast);
        },
        [types.SHOW_LOADER]: ({ commit }, show) => {
            commit(types.MUTATE_SHOW_LOADER, show);
        },
        [types.RESET_FILTERS]: ({ commit }) => {
            localStorage.setItem("category", null);
            localStorage.setItem("businessUnits", []);
            localStorage.setItem("scopes", []);
            localStorage.setItem("sites", []);
            localStorage.setItem("tags", []);
            localStorage.setItem("validation", null);
            commit(types.MUTATE_RESET_FILTERS);
        },
    },
    getters: {
        getFilters: (state) => () => {
            return state.filters;
        },
        getCurrentPeriod: (state) => () => {
            return state.data.periods.find(
                (x) => x.id === state.filters.period
            );
        },
        getPermissions: (state) => (query) => {
            if (!state.user?.role_info?.[query]) return [];
            return state.user.role_info[query];
        },
        getPermissionsArray: (state) => () => {
            return state.user.permissions;
        },
        getUser: (state) => () => {
            return state.user;
        },
    },
});
