<template>
    <Accordion :value="['0']" multiple>
        <AccordionPanel value="0">
            <AccordionHeader>
                {{ $t("no_group") }} ({{ sites.length }})
            </AccordionHeader>
            <AccordionContent>
                <div
                    class="flex justify-content-between align-items-center"
                    v-for="site in sites"
                    :key="'site' + site.id"
                >
                    <div class="flex align-items-center">
                        <span class="mr-3">
                            {{ flagMapper[site.country_code] }}
                        </span>
                        {{ site.name }}
                    </div>
                    <div class="flex gap-1">
                        <SiteHistoryModal :site="site" />
                        <Button
                            text
                            rounded
                            icon="fa fa-edit"
                            @click="handleSiteEdit(site)"
                            severity="secondary"
                            v-tooltip.top="$t('edit')"
                        />
                    </div>
                </div>
            </AccordionContent>
        </AccordionPanel>
        <AccordionPanel
            v-for="(siteGroup, i) in siteGroups"
            :key="'sitegroup' + siteGroup.id"
            :value="i + 1"
        >
            <AccordionHeader>
                <div
                    class="flex justify-content-between align-items-center w-full"
                >
                    {{ siteGroup.name }} ({{ siteGroup.sites.length }})
                    <Button
                        text
                        rounded
                        icon="fa fa-edit"
                        @click.stop="handleSiteGroupEdit(siteGroup)"
                        severity="secondary"
                    />
                </div>
            </AccordionHeader>
            <AccordionContent>
                <div class="flex flex-column gap-3">
                    <div
                        class="flex justify-content-between align-items-center"
                        v-for="site in siteGroup.sites"
                        :key="'site' + site.id"
                    >
                        <div class="flex align-items-center">
                            <span class="mr-3">
                                {{ flagMapper[site.country_code] }}
                            </span>
                            {{ site.name }}
                        </div>
                        <div class="flex gap-1">
                            <SiteHistoryModal :site="site" />
                            <Button
                                text
                                rounded
                                icon="fa fa-edit"
                                @click="handleSiteEdit(site)"
                                severity="secondary"
                            />
                        </div>
                    </div>
                </div>
            </AccordionContent>
        </AccordionPanel>
    </Accordion>
</template>

<script setup lang="ts">
import { type PropType } from "vue";
import Button from "primevue/button";
import Accordion from "primevue/accordion";
import AccordionPanel from "primevue/accordionpanel";
import AccordionHeader from "primevue/accordionheader";
import AccordionContent from "primevue/accordioncontent";
import SiteHistoryModal from "@/components/company/structure/SiteHistoryModal.vue";

import { flagMapper } from "@/util/countries.js";

defineProps({
    sites: {
        type: Array as PropType<TSite[]>,
        required: true,
    },
    siteGroups: {
        type: Array as PropType<TSiteGroup[]>,
        required: true,
    },
});

const emit = defineEmits(["edit-site", "edit-site-group"]);

const handleSiteEdit = (site: TSite) => {
    emit("edit-site", site);
};

const handleSiteGroupEdit = (siteGroup: TSiteGroup) => {
    emit("edit-site-group", siteGroup);
};
</script>
