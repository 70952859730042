<template>
    <LoadingWrapper :loading="loading" class="pb-8">
        <!-- Empty Custom Metrics View -->
        <empty-metric-view
            v-if="!customMetrics"
            @create-metric="createCustomMetric"
        />

        <!-- Custom Metrics List -->
        <div v-else>
            <!-- Custom Metric Header -->
            <metric-header
                @expand-all="toggleExpandAll"
                @collapse-all="toggleCollapseAll"
                @create-metric="createCustomMetric"
                @search="search = $event"
                :expandable="expandable"
            ></metric-header>

            <MetricAccordion
                :metrics="filteredCustomMetrics"
                :expandAll="expandAll"
                :collapseAll="collapseAll"
                @select-metric="selectMetric"
                @refresh="init"
                ref="metric-accordion"
            >
                <template #default="{ metric }">
                    <metric-table
                        :ref="`metricAccordionTable-${metric.id}`"
                        :customMetric="metric"
                        :navigate-metric-value="navigateMetricValueItem"
                        @reset-navigation="navigateMetricValueItem = 0"
                        @select-metric-value="selectedCustomMetricAction"
                    />
                </template>
            </MetricAccordion>
        </div>

        <!-- Custom Metrics Sidebar -->
        <metric-sidebar
            ref="metric_sidebar_component"
            :selectedCustomMetric="selectedCustomMetric"
            :selectedCustomMetricValue="selectedCustomMetricValue"
            @navigate-metric-values="navigateMetricValues"
            @refresh="init"
        >
            <template v-slot:attachments>
                <MetricsAttachments
                    name="attachments"
                    :customMetricValueId="selectedCustomMetricValue.id"
                />
            </template>
        </metric-sidebar>
    </LoadingWrapper>
</template>
<script>
import customMetricsApi from "@/api/custom_metrics";
import MetricHeader from "@/components/custom_metrics/MetricHeader.vue";
import MetricSidebar from "@/components/custom_metrics/metricsSidebar/MetricSidebar.vue";
import { mapState } from "vuex";
import EmptyMetricView from "./EmptyMetricView.vue";
import MetricTable from "./metricsAccordion/MetricTable.vue";
import MetricsAttachments from "./metricsSidebar/data-input/MetricAttachments.vue";
import MetricAccordion from "./metricsAccordion/MetricAccordion.vue";
import LoadingWrapper from "@/components/elements/LoadingWrapper.vue";

export default {
    name: "MetricList",
    components: {
        MetricSidebar,
        MetricHeader,
        EmptyMetricView,
        MetricTable,
        MetricsAttachments,
        MetricAccordion,
        LoadingWrapper,
    },

    data() {
        return {
            customMetrics: [],
            selectedCustomMetric: null,
            selectedCustomMetricValue: null,
            expandAll: false,
            collapseAll: false,
            loading: true,
            expandable: true,
            navigateMetricValueItem: 0,
            sidebarVisible: false,
            search: "",
        };
    },

    mounted() {
        this.init();
    },

    computed: {
        ...mapState({
            businessUnits: (state) => state.data.businessUnits,
            sites: (state) => state.data.sites,
            periodId: (state) => state.filters.period,
        }),

        selectSites() {
            const sites = this.sites.map((x) => {
                return { value: x.id, text: x.name };
            });
            return sites;
        },

        selectBu() {
            const bu = this.businessUnits.map((x) => {
                return { value: x.id, text: x.name };
            });
            return bu;
        },

        filteredCustomMetrics() {
            return this.customMetrics.filter((x) =>
                x.name.toLowerCase().includes(this.search.toLowerCase())
            );
        },
    },

    methods: {
        init() {
            this.getAllCustomMetrics();
        },

        async getAllCustomMetrics() {
            this.loading = true;

            try {
                const response = await customMetricsApi.list();
                this.customMetrics = response.data.data;
            } catch (error) {
                console.log(error);
            } finally {
                this.loading = false;
            }
        },

        // Custom metric actions //
        createCustomMetric() {
            this.selectedCustomMetric = null;
            this.selectedCustomMetricValue = null;
            this.$refs.metric_sidebar_component.open();
        },

        selectMetric(metric) {
            this.selectedCustomMetric = { ...metric };
            this.selectedCustomMetricValue = null;
            this.$refs.metric_sidebar_component.open();
        },
        selectedCustomMetricAction(value) {
            // If the period changed, we should only set a new metric value when the sidebar is opened
            const sidebar = this.$refs.metric_sidebar_component;
            if (!sidebar) return;
            if (
                !sidebar.showModal &&
                !sidebar.sidebarVisible &&
                value.periodChanged
            )
                return;

            // We recieve both the metric and the metric value
            const metricValue = value.metricValue;
            const customMetric = value.customMetric;

            // If the metric value is null, we are creating a new metric value, so we set the period_id
            metricValue.period_id === null
                ? (metricValue.period_id = this.periodId)
                : metricValue;
            this.selectedCustomMetricValue = { ...metricValue };
            this.selectedCustomMetric = { ...customMetric };

            // Open the sidebar
            this.$nextTick(() => {
                this.$refs.metric_sidebar_component.open();
            });
        },

        // Controls accordion expand/collapse
        toggleExpandAll() {
            this.expandAll = true;
            this.expandable = false;
            this.$nextTick(() => (this.expandAll = false));
        },
        toggleCollapseAll() {
            this.collapseAll = true;
            this.expandable = true;
            this.$nextTick(() => (this.collapseAll = false));
        },

        // Controls for navigation has been hidden for now
        navigateMetricValues(navigationDetails) {
            const { metric_id } = navigationDetails;
            this.$refs[`metricAccordionTable-${metric_id}`][0].navigateValue(
                navigationDetails
            );
        },
    },
    watch: {
        periodId() {
            this.init();
        },
    },
};
</script>
