<template>
    <Dialog
        :header="title"
        v-model:visible="localShowModal"
        modal
        :style="{ width: '50%' }"
    >
        <form @submit.prevent="save" class="cc-form" id="site-form">
            <div class="cc-form-group cc-form-group--required">
                <label for="name">{{ $t("label_name") }}</label>
                <CcInput id="name" v-model="data.name" required></CcInput>
            </div>
            <div class="cc-form-group">
                <label for="surface_area">{{ $t("label_surface_m2") }}</label>
                <InputNumber
                    id="surface_area"
                    v-model="data.surfaceArea"
                ></InputNumber>
            </div>
            <div class="cc-form-group">
                <label for="siteGroup">{{ $t("label_group") }}</label>
                <CcSelect
                    id="siteGroup"
                    v-model="data.siteGroupId"
                    :options="groupOptions"
                ></CcSelect>
            </div>
            <Divider />
            <h2>{{ $t("label_address") }}</h2>
            <div class="cc-form-group">
                <label for="input-new-site-address-addressLine1">
                    {{ $t("label_street") }}
                </label>
                <CcInput
                    id="input-new-site-address-addressLine1"
                    v-model="data.address.addressLine1"
                ></CcInput>
            </div>
            <div class="cc-form-group">
                <label for="input-new-site-address-postalcode">
                    {{ $t("label_zip") }}
                </label>
                <CcInput
                    id="input-new-site-address-postalcode"
                    v-model="data.address.postcode"
                ></CcInput>
            </div>
            <div class="cc-form-group">
                <label for="input-new-site-address-city">
                    {{ $t("label_city") }}
                </label>
                <CcInput
                    id="input-new-site-address-city"
                    v-model="data.address.city"
                ></CcInput>
            </div>
            <div class="cc-form-group cc-form-group--required">
                <label for="select-new-site-address-country">
                    {{ $t("country") }}
                </label>
                <CcSelect
                    id="select-new-site-address-country"
                    required
                    v-model="data.address.countryCode"
                    :options="countrySelectOptions"
                ></CcSelect>
            </div>
        </form>

        <template #footer>
            <div class="flex justify-content-end gap-2">
                <Button
                    v-if="!isNewSite && $can('delete', 'site')"
                    @click="handleDelete(site)"
                    severity="danger"
                >
                    {{ $t("delete_site") }}
                </Button>
                <Button
                    @click="localShowModal = false"
                    variant="secondary"
                    outlined
                >
                    {{ $t("cancel") }}
                </Button>
                <Button form="site-form" type="submit"
                    >{{ $t("save") }}
                </Button>
            </div>
        </template>
    </Dialog>
</template>

<script>
import Dialog from "primevue/dialog";
import Divider from "primevue/divider";
import Button from "primevue/button";
import siteApi from "@/api/site.js";
import { getCountrySelectOptions } from "@/util/countries.js";
import InputNumber from "primevue/inputnumber";

const initialData = {
    name: "",
    surfaceArea: null,
    siteGroupId: -1,
    contact: {
        name: "",
        lastName: "",
        email: "",
        save: false,
    },
    address: {
        addressLine1: "",
        postcode: "",
        city: "",
        countryCode: "",
    },
};

export default {
    components: {
        Dialog,
        Divider,
        Button,
        InputNumber,
    },
    props: {
        modelValue: {
            type: Boolean,
            required: true,
        },
        site: {
            type: Object,
            required: false,
            default: null,
        },
        siteGroups: {
            type: Array,
            required: false,
            default: () => [],
        },
    },
    data() {
        return {
            data: {},
        };
    },
    computed: {
        localShowModal: {
            get() {
                return this.modelValue;
            },
            set(val) {
                if (val === false) this.data = structuredClone(initialData);
                this.$emit("update:modelValue", val);
            },
        },
        groupOptions() {
            const groups = this.siteGroups.map((sg) => ({
                text: sg.name,
                value: sg.id,
            }));
            groups.unshift({ text: "No group", value: -1 });
            return groups;
        },
        isNewSite() {
            return !this.site || this.site.id <= 0;
        },
        title() {
            return this.isNewSite ? "New site" : "Edit site";
        },
        countrySelectOptions() {
            return getCountrySelectOptions();
        },
    },
    created() {
        // Create the data structure on first create
        this.data = structuredClone(initialData);
        this.init();
    },
    methods: {
        init() {
            if (this.isNewSite) {
                this.data = structuredClone(initialData);
            } else {
                this.data.name = this.site.name;
                this.data.siteGroupId = this.site.group_id;
                this.data.surface_area = this.site.surface_area;
                this.data.address = {
                    addressLine1: this.site.address_line_1,
                    postcode: this.site.postcode,
                    city: this.site.city,
                    countryCode: this.site.country_code,
                };
            }
        },
        save() {
            const { name, siteGroupId, contact, address, surfaceArea } =
                this.data;
            const params = {
                name,
                siteGroupId,
                contact,
                address,
                surfaceArea,
            };

            if (this.isNewSite) this.addSite(params);
            else this.updateSite(params);
        },
        async addSite(params) {
            try {
                await siteApi.addSite(params);
                this.$emit("update");
                this.init();
            } catch (error) {
                console.log(error);
            }
        },
        async updateSite(params) {
            try {
                await siteApi.updateSite(this.site.id, params);
                this.$emit("update");
                this.init();
            } catch (error) {
                console.log(error);
            }
        },
        async deleteSite(site) {
            try {
                await siteApi.deleteSite(site.id);
                this.$emit("update");
                this.init();
            } catch (error) {
                console.log(error);
            }
        },
        handleDelete(site) {
            this.$confirm.require({
                message: `Are you sure you want to delete ${site.name}?`,
                header: "Delete site?",
                icon: "fa fa-exclamation-triangle",
                rejectProps: {
                    label: this.$t("cancel"),
                    class: "secondary",
                    outlined: true,
                },
                acceptProps: {
                    label: this.$t("delete"),
                },
                accept: () => this.deleteSite(site),
            });
        },
    },
    watch: {
        site() {
            this.init();
        },
    },
};
</script>
