import * as types from "../types";

export const dataInput = {
    state: {
        reloadAll: 0,
        sort: "site",
        selected: [],
        selectAll: false,
        allSelected: false,
        move: null,
    },
    mutations: {
        [types.MUTATE_RELOAD_ALL_DATA_LISTS]: (state) => {
            state.reloadAll++;
        },
        [types.MUTATE_SET_SORT]: (state, sort) => {
            state.sort = sort;
        },
        [types.MUTATE_SELECT_SOURCE]: (state, data) => {
            if (Array.isArray(data)) {
                data.forEach((id) => {
                    const index = state.selected.findIndex((x) => x === id);
                    if (index === -1) state.selected.push(id);
                });
            } else {
                const index = state.selected.findIndex((x) => x === data);
                if (index === -1) state.selected.push(data);
                else {
                    state.selected.splice(index, 1);
                    state.allSelected = false;
                }
            }
        },
        [types.MUTATE_SELECT_ALL_SOURCES]: (state) => {
            state.selectAll = !state.selectAll;
            state.allSelected = true;
        },
        [types.MUTATE_DESELECT_ALL_SOURCES]: (state) => {
            state.selected = [];
            state.allSelected = false;
        },
        [types.MUTATE_MOVE]: (state, direction) => {
            state.move = direction;
        },
    },
    actions: {
        [types.RELOAD_ALL_DATA_LISTS]: ({ commit }) => {
            commit(types.MUTATE_RELOAD_ALL_DATA_LISTS);
        },
        [types.SET_SORT]: ({ commit }, sort) => {
            commit(types.MUTATE_SET_SORT, sort);
        },
        [types.SELECT_SOURCE]: ({ commit }, data) => {
            commit(types.MUTATE_SELECT_SOURCE, data);
        },
        [types.SELECT_ALL_SOURCES]: ({ commit }) => {
            commit(types.MUTATE_SELECT_ALL_SOURCES);
        },
        [types.DESELECT_ALL_SOURCES]: ({ commit }) => {
            commit(types.MUTATE_DESELECT_ALL_SOURCES);
        },
        [types.MOVE]: ({ commit }, direction) => {
            commit(types.MUTATE_MOVE, direction);
        },
    },
};
