<template>
    <!-- Empty Custom Metrics Page-->
    <div class="flex flex-column justify-content-center align-items-center">
        <div class="custom-metric__container">
            <img
                :src="bgImagePath"
                alt="Cloud image"
                class="custom-metric__img"
            />
            <div class="custom-metric__text-overlay">
                <h2>{{ $t("empty_metric_header") }}</h2>
                <p>
                    {{ $t("empty_metric_text") }}
                </p>
                <Button
                    @click="$emit('create-metric')"
                    :label="$t('new_metric')"
                />
            </div>
        </div>
    </div>
</template>

<script>
import no_metrics_image from "@/assets/images/metrics/metrics_background.png";
import Button from "primevue/button";

export default {
    name: "EmptyMetricView",
    components: {
        Button,
    },
    data() {
        return {
            bgImagePath: no_metrics_image,
        };
    },
};
</script>
