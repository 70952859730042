import http from "../http-common";

const api = {
    list: () => {
        return http.get(`/sites`);
    },

    listDeleted: () => {
        return http.get(`/sites/deleted`);
    },

    getSite: (siteId) => {
        return http.get(`/site/${siteId}/details`);
    },

    addSite: (params) => {
        return http.post("/site/add", params);
    },

    deleteSite: (siteId) => {
        return http.post(`/site/${siteId}/delete`);
    },

    updateSite: (siteId, params) => {
        return http.post(`/site/${siteId}/update`, params);
    },

    getSiteActivity: (siteId) => {
        return http.get(`/site/${siteId}/activity`);
    },

    getSiteHistoricalNames: (siteId) => {
        return http.get(`/site/${siteId}/historical-names`);
    },
};

export default api;
