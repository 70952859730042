<template>
    <div>
        <Accordion multiple v-model:value="openedMetrics" lazy>
            <AccordionPanel
                v-for="metric in metrics"
                :key="metric.id"
                :value="metric.id"
                class="mb-2"
            >
                <AccordionHeader>
                    <div
                        class="flex justify-content-between align-items-center w-full"
                    >
                        <div class="flex align-items-center gap-2">
                            <span
                                class="flex flex-column align-items-start justify-content-center mr-4"
                            >
                                <h4>{{ metric.name }}</h4>
                                <div
                                    :id="metric.id + 'metric-description'"
                                    v-if="metric.description"
                                    class="light text-sm cc-table__column--ellipsis"
                                    v-tooltip.bottom="
                                        metric.description.length > 40
                                            ? metric.description
                                            : null
                                    "
                                >
                                    {{ metric.description }}
                                </div>
                            </span>
                            <Chip class="badge text-sm" variant="secondary">
                                {{ metric.unit }}
                            </Chip>
                            <Chip class="badge text-sm" variant="secondary">
                                {{ selectedDomain(metric) }}
                            </Chip>
                        </div>
                        <div
                            class="flex gap-2 mr-4"
                            v-if="period?.locked !== 1"
                        >
                            <Button
                                v-if="$can('update', 'metric')"
                                icon="fas fa-pencil"
                                rounded
                                outlined
                                @click.stop="$emit('select-metric', metric)"
                                v-tooltip.bottom="$t('edit')"
                            ></Button>
                            <Button
                                v-if="$can('delete', 'metric')"
                                icon="fas fa-trash"
                                rounded
                                outlined
                                @click.stop="openDeleteMetricModal(metric)"
                                v-tooltip.bottom="$t('delete')"
                                severity="danger"
                            ></Button>
                        </div>
                    </div>
                </AccordionHeader>
                <AccordionContent>
                    <slot :metric="metric" />
                </AccordionContent>
            </AccordionPanel>
        </Accordion>
    </div>
</template>

<script setup lang="ts">
import { type PropType, ref, computed, watch } from "vue";
import Accordion from "primevue/accordion";
import AccordionContent from "primevue/accordioncontent";
import AccordionHeader from "primevue/accordionheader";
import AccordionPanel from "primevue/accordionpanel";
import Chip from "primevue/chip";
import Button from "primevue/button";
import { useStore } from "vuex";
import { useConfirm } from "primevue/useconfirm";
import { useI18n } from "vue-i18n";
import customMetricsApi from "@/api/custom_metrics";

const store = useStore();
const confirm = useConfirm();
const { t } = useI18n();

const openedMetrics = ref<number[]>([]);
const period = computed(() => store.getters.getCurrentPeriod());

const props = defineProps({
    metrics: {
        type: Array as PropType<TMetric[]>,
        required: true,
    },
    expandAll: {
        type: Boolean,
        required: false,
        default: false,
    },
    collapseAll: {
        type: Boolean,
        required: false,
        default: false,
    },
});

const emit = defineEmits(["refresh", "select-metric"]);

watch(
    () => props.collapseAll,
    (val, oldVal) => {
        if (val === oldVal) return;
        if (val) {
            openedMetrics.value = [];
        }
    }
);

watch(
    () => props.expandAll,
    (val, oldVal) => {
        if (val === oldVal) return;
        if (val) {
            openedMetrics.value = props.metrics.map((metric) => metric.id);
        }
    }
);

const openDeleteMetricModal = (metric: TMetric) => {
    confirm.require({
        message: t("delete_metric_message"),
        header: `${t("delete")} ${metric.name} ?`,
        icon: "fa fa-trash",
        rejectProps: {
            label: t("cancel"),
            class: "secondary",
            outlined: true,
        },
        acceptProps: {
            label: t("delete_confirm"),
            severity: "danger",
        },
        accept: () => {
            deleteMetric(metric.id);
        },
    });
};

const deleteMetric = async (id: Number) => {
    try {
        await customMetricsApi.delete(id);
        emit("refresh");
    } catch (error) {
        console.log(error);
    }
};

const selectedDomain = (metric: TMetric) => {
    switch (true) {
        case metric.global_site === 1 && metric.global_business_unit === 0:
            return t("metric_bu_specific");
        case metric.global_site === 0 && metric.global_business_unit === 1:
            return t("metric_site_specific");
        case metric.global_site === 1 && metric.global_business_unit === 1:
            return t("metric_whole_company");
        case metric.global_site === 0 && metric.global_business_unit === 0:
            return t("metric_combined");
        default:
            return "";
    }
};
</script>
