<template>
    <div>
        <cc-header
            class="border-bottom"
            :title="$t('cm_header')"
            icon="far fa-flag"
        ></cc-header>

        <div class="content-container">
            <metric-list />
        </div>
    </div>
</template>

<script>
import MetricList from "@/components/custom_metrics/MetricList.vue";
import CcHeader from "@/components/elements/CcHeader.vue";

export default {
    name: "CompanyMetrics",
    components: {
        CcHeader,
        MetricList,
    },
};
</script>
