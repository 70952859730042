<template>
    <div>
        <Button
            text
            rounded
            icon="fa fa-history"
            @click="handleOpenModal"
            severity="secondary"
            v-tooltip.top="$t('site_history')"
        />
        <Dialog
            v-model:visible="visible"
            :header="$t('site_history')"
            :style="{ width: '50vw' }"
            modal
        >
            <LoadingWrapper :loading="loading">
                <ActivityList :activityData="siteHistory" />
            </LoadingWrapper>
            <template #footer>
                <Button
                    icon="fa fa-close"
                    @click="visible = false"
                    :label="$t('close')"
                />
            </template>
        </Dialog>
    </div>
</template>
<script setup lang="ts">
import { ref } from "vue";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import ActivityList from "@/components/elements/ActivityList.vue";
import LoadingWrapper from "@/components/elements/LoadingWrapper.vue";
import siteApi from "@/api/site";

const siteHistory = ref([]);

const visible = ref(false);
const loading = ref(false);

const props = defineProps({
    site: {
        type: Object,
        required: true,
    },
});

const handleOpenModal = async () => {
    loading.value = true;
    await getSiteHistory();
    loading.value = false;
    visible.value = true;
};

const getSiteHistory = async () => {
    try {
        const response = await siteApi.getSiteActivity(props.site.id);
        siteHistory.value = response.data;
    } catch (error) {
        console.log(error);
    }
};
</script>
